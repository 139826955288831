.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Ratings */

.rating-outer {
  display: inline-block;
  position: relative;
  font-family: FontAwesome;
  color: #fdcc0d;
  max-width: fit-content;
}

.rating-outer::before {
  content: "\f006 \f006 \f006 \f006 \f006";
}

.rating-inner {
  position: absolute;
  top: 0;
  left: 0;
  white-space: nowrap;
  overflow: hidden;
  width: 0;
}

.rating-inner::before {
  content: "\f005 \f005 \f005 \f005 \f005";
  color: #f8ce0b;
}

.shadow-bold:hover {
  text-shadow: 1px 0 0 black;
}

/* Navbar shadow */
.shadow {
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.1);
}
.btn-shadow {
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.06);
}
.card-shadow {
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.07);
}
